import ajax from "@/ajax";

export default {
  getEtfs(traders, currencies, captcha) {
    let qs = require('qs');
    return ajax.get("/api/etfs", {
      params: { traders: traders, currencies: currencies, captcha: captcha },
      paramsSerializer: function(params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      },
    }).then(res => {
      return res.data;
    });
  }
};
