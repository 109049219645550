<template>
  <v-app>
    <v-app-bar app color="secondary" dark>
      <v-btn href="https://inwestomat.eu/" target="_blank" style="text-transform: none" text>
        <span class="mr-2">Inwestomat</span>
      </v-btn>
      <v-btn href="https://www.justetf.com/en/" target="_blank" style="text-transform: none" text>
        <span class="mr-2">justETF</span>
      </v-btn>
      <v-btn href="https://pypi.org/project/yfinance/" target="_blank" style="text-transform: none" text>
        <span class="mr-2">yFinance</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn href="https://bossa.pl/" target="_blank" text style="text-transform: none">
        <span class="mr-2">BOSSA DM</span>
      </v-btn>
      <v-btn href="https://mdm.pl/" target="_blank" text style="text-transform: none">
        <span class="mr-2">mBank DM</span>
      </v-btn>
      <v-btn href="https://www.xtb.com/pl" target="_blank" text style="text-transform: none">
        <span class="mr-2">X-Trade Brokers</span>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row class="text-center">
          <v-col>
            <router-link to="/" class="mr-2">
              <v-btn color="primary" elevation="1">ETFs
                <v-icon right dark>mdi-bank</v-icon>
              </v-btn>
            </router-link>
            <router-link to="/glossary" class="mr-2">
              <v-btn color="secondary" elevation="1">Glossary
                <v-icon right dark>mdi-book-open-variant</v-icon>
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "App",
  data: () => ({
    //
  })
};
</script>
<style lang="scss">
a {
  text-decoration: none;
}
</style>
