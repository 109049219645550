<template>
  <v-container class="home">
    <v-row class="text-center">
      <v-col class="mb-4">
        <v-card>
          <v-card-title>
            ETFy dostępne w polskich DM
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="response.etfs"
              :expanded.sync="expanded"
              :search="search"
              item-key="isin"
              :loading="loading"
              :items-per-page="50"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50, 100]
              }"
              show-expand
              multi-sort
              class="elevation-1">

            <template v-slot:top>
              <v-container>
                <v-row no-gutters>
                  <v-col md="8" class="d-flex">
                    <v-checkbox
                        @click="triggerQueryEtf"
                        v-model="prompt.usd"
                        label="USD" class="pa-1">
                    </v-checkbox>
                    <v-checkbox
                        @click="triggerQueryEtf"
                        v-model="prompt.eur"
                        label="EUR" class="pa-1">
                    </v-checkbox>
                    <v-checkbox
                        @click="triggerQueryEtf"
                        v-model="prompt.gbp"
                        label="GBP" class="pa-1">
                    </v-checkbox>
                  </v-col>
                  <v-col md="2" class="d-flex">
                    <v-checkbox
                        @click="triggerQueryEtf"
                        v-model="prompt.xtb">
                      <template v-slot:label>
                        <div>XTB</div>
                        <div class="pa-1 pr-5">
                          <v-img contain :src="require('@/assets/xtb.png')" width="16" />
                        </div>
                      </template>
                    </v-checkbox>
                    <v-spacer></v-spacer>
                    <v-checkbox
                        @click="triggerQueryEtf"
                        v-model="prompt.mbank">
                      <template v-slot:label>
                        <div>mBank</div>
                        <div class="pa-1 pr-5">
                          <v-img contain :src="require('@/assets/mbank.png')" width="16" />
                        </div>
                      </template>
                    </v-checkbox>
                    <v-checkbox
                        @click="triggerQueryEtf"
                        v-model="prompt.bossa">
                      <template v-slot:label>
                        <div>BOSSA</div>
                        <div class="pa-1 pr-5">
                          <v-img contain :src="require('@/assets/bossa.png')" width="16" />
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="5" class="text-left pa-4">
                <v-container>
                  <v-row no-gutters>
                    <v-col md="5" class="font-weight-medium">
                      ISIN:
                    </v-col>
                    <v-col md="6" >
                      {{ item.isin }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col md="5" class="font-weight-medium">
                      Fund domicile:
                    </v-col>
                    <v-col md="6">
                      {{ item.fund_domicile }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col md="5" class="font-weight-medium">
                      Fund currency:
                    </v-col>
                    <v-col md="6">
                      {{ item.fund_currency }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col md="5" class="font-weight-medium">
                      Replication:
                    </v-col>
                    <v-col md="6">
                      {{ item.replication }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col md="5" class="font-weight-medium">
                      Distribiution frequency:
                    </v-col>
                    <v-col md="5">
                      {{ item.distribution_frequency }}
                    </v-col>
                  </v-row>
                </v-container>
              </td>
              <td :colspan="3" vertical-align="top">
                <v-container >
                  <v-row no-gutters v-for="value in item.traders" :key="value.ticker + '.' + value.trader" class="ma-2">
                    <v-col md="3">
                      <v-img v-if="value.trader === 'xtb'" contain :src="require('@/assets/xtb.png')"
                             transition="scale-transition" width="25" class="d-print-flex"/>
                      <v-img v-if="value.trader === 'bossa'" contain :src="require('@/assets/bossa.png')"
                             transition="scale-transition" width="25" class="d-print-flex"/>
                      <v-img v-if="value.trader === 'mbank'" contain :src="require('@/assets/mbank.png')"
                             transition="scale-transition" width="25" class="d-print-flex"/>
                    </v-col>
                    <div class="align-self-center">
                      {{ value.ticker }}.{{ value.ric_code }} ({{ value.currency }})
                    </div>
                  </v-row>
                </v-container>
              </td>
              <td :colspan="7">
                <v-container>
                  <v-row no-gutters>
                    <v-col md="1"></v-col>
                    <v-col md="2" class="font-weight-medium">
                      Returns
                    </v-col>
                    <v-col v-if="item.distribution_policy === 'Distributing'" md="4" class="font-weight-medium">
                      Dividends
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-for="(value, key) in item.returns_by_years" v-bind:key="key" vertical-align="top">
                    <v-col md="1" class="font-weight-medium text-right">
                      {{ key }}
                    </v-col>
                    <v-col md="2" class="text-right">
                      {{ value }}%
                    </v-col>
                    <v-col v-if="item.distribution_policy === 'Distributing'" md="2" class="text-right">
                    <span v-if="item.dividend_yields_value[key]">
                      {{ item.dividend_yields_value[key] }} EUR
                    </span>
                    </v-col>
                    <v-col v-if="item.distribution_policy === 'Distributing'" md="2" class="text-right">
                    <span v-if="item.dividend_yields_pcnt[key]">
                      {{ item.dividend_yields_pcnt[key] }}%
                    </span>
                    </v-col>
                  </v-row>
                </v-container>
              </td>
            </template>
            <template v-slot:item.volatility_1y="{ item }">
              <v-chip
                  :color="getVolatilityColor(item.volatility_1y)"
                  dark label>
                {{ item.volatility_1y }}%
              </v-chip>
            </template>
            <template v-slot:item.fund_size="{ item }">
              <v-chip
                  :color="getFundSizeColor(item.fund_size)"
                  dark label>
                {{ item.fund_size }}
              </v-chip>
            </template>
            <template v-slot:item.ter="{ item }">
              <v-chip
                  :color="getTERColor(item.ter)"
                  dark label>
                {{ item.ter }}%
              </v-chip>
            </template>
            <template v-slot:item.price="{ item }">
              {{ item.price }}
            </template>
            <template v-slot:item.isin="{ item }">
              {{ item.isin }}
            </template>
            <template v-slot:item.custom="{ item }">
              <a v-bind:href="'https://www.justetf.com/de-en/etf-profile.html?isin='+ item.isin" target="_blank">
                <v-icon right>
                  mdi-launch
                </v-icon>
              </a>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <vue-recaptcha
        ref="recaptcha"
        @verify="recaptchaValidated"
        @expired="triggerQueryEtf"
        @render="triggerQueryEtf"
        size="invisible"
        sitekey="6LeWIR8eAAAAAAbWyiy5nUH-xGe7I5_YUZE4BPgc">
    </vue-recaptcha>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">{{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="indigo" text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
// @ is an alias to /src
import EtfService from "@/api/EtfService";
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: "Home",
  data: () => ({
      response: {
        etfs: []
      },
      showError: false,
      loading: true,
      search: '',
      expanded: [],
      singleExpand: false,
      prompt: {
        usd: true,
        eur: true,
        gbp: true,
        bossa: true,
        mbank: true,
        xtb: true
      },
      headers: [
        { text: 'Fund name', value: 'fund_name', width: '350px'},
        { text: 'Region', value: 'fund_region' },
        { text: 'Subject', value: 'fund_subject' },
        { text: 'Dist. policy', value: 'distribution_policy' },
        { text: 'TER', align: 'end', value: 'ter', width: '90px' },
        { text: 'Volatility', align: 'end', value: 'volatility_1y', width: '90px' },
        { text: 'Fund size (m EUR)', align: 'end', value: 'fund_size', width: '90px' },
        { text: 'Price (EUR)', align: 'end', value: 'price', width: '90px' },
        { text: '1M', align: 'end', value: 'returns_by_period.1M', width: '70px' },
        { text: '3M', align: 'end', value: 'returns_by_period.3M', width: '70px' },
        { text: '6M', align: 'end', value: 'returns_by_period.6M', width: '70px' },
        { text: '1Y', align: 'end', value: 'returns_by_period.1Y', width: '70px' },
        { text: '3Y', align: 'end', value: 'returns_by_period.3Y', width: '70px' },
        { text: '', align: 'end', value: 'custom'}
      ],
      snackbar: {
        show: false,
        message: "",
        color: ""
      },
  }),
  methods: {
    sendQueryEtf(traders, currencies, captcha) {
      EtfService.getEtfs(traders, currencies, captcha)
          .then(res => {
            console.log(res);
            this.response = res;
            this.showError = false;
            this.loading = false
          })
          .catch(error => {
            console.log(error);
            this.showError = true;
            this.showSnackbar("Request to API failed!", "warning");
          });
    },
    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = "true";
    },
    getVolatilityColor (volatility_1y) {
      if (volatility_1y > 10) return 'red'
      else if (volatility_1y > 5) return 'orange'
      else return 'green'
    },
    getFundSizeColor (fund_size) {
      if (fund_size < 100) return 'red'
      else if (fund_size < 500) return 'orange'
      else return 'green'
    },
    getTERColor (ter) {
      if (ter > 0.7) return 'red'
      else if (ter > 0.3) return 'orange'
      else return 'green'
    },
    recaptchaValidated(recaptchaToken) {
      let traders = []
      let currencies = []
      if (this.prompt.eur) currencies.push('EUR');
      if (this.prompt.usd) currencies.push('USD');
      if (this.prompt.gbp) currencies.push('GBP');
      if (this.prompt.bossa) traders.push('bossa');
      if (this.prompt.mbank) traders.push('mbank');
      if (this.prompt.xtb) traders.push('xtb');
      this.loading = true;
      this.sendQueryEtf(traders, currencies, recaptchaToken);
    },
    triggerQueryEtf() {
      localStorage.removeItem('recaptchaToken')
      this.$refs.recaptcha.reset();
      this.$refs.recaptcha.execute();
    }
  },
  computed: {
    console: () => console
  },
  components: { VueRecaptcha }
};
</script>
