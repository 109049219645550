import axios from "axios";

let apiUrl = "http://127.0.0.1:5001";

if (location.hostname.indexOf("nexocode.com") !== -1) {
  apiUrl = "https://" + location.hostname;
}

let ajax = axios.create({
  baseURL: apiUrl ? apiUrl : "http://localhost:8080",
});

export default { ...ajax };
